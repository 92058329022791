import React from "react";
import { buildImageObj } from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import Container from "../container";
import { Link } from "gatsby";

import styles from "./security.module.css";

function Security({}) {
  return (
    <section className={styles.security}>
      <Container>
        <h2>
          We make sure everything is <span>secure</span>
        </h2>
        <p>
          The security of our users’ data is a fundamental part of our business and our top priority
          at Passiv. We use the latest technology and have strict processes in place to protect your
          data. We monitor continuously to ensure the security of your information.
        </p>
        <Link to="/security/" className={styles.btn1}>
          Learn More
        </Link>
      </Container>
    </section>
  );
}

export default Security;
