import React from "react";
import {buildImageObj,cn} from "../../lib/helpers";
import { imageUrlFor } from "../../lib/image-url";
import Container from "../container";

import graphA from "../../images/features-graphA.png"
import graphA2x from "../../images/features-graphA@2x.png"
import graphB from "../../images/features-graphB.png"
import graphB2x from "../../images/features-graphB@2x.png"

import styles from "./features.module.css";

function Features({}) {
  return (
    <section className={styles.features}>
      <Container>
        <div className={styles.flexContainer}>
          <div className={styles.flexImgBox}>
            <img src={graphA} srcSet={cn(graphA2x,'2x')} alt="Investment Graph" />
          </div>
          <div className={styles.flexCopy}>
            <h2>Save time and make investing easy</h2>
            <p>Investing can be hard enough without all the time that it takes to do it. Passiv leaves you with more time to research so you can further your investments.</p>
            <ul>
              <li>One-click trades make rebalancing across multiple accounts easy.</li>
              <li>Free up time and mental space with automative investing that lets you know when you need to sell or trade.</li>
              <li>Build a model portfolio once, and Passiv takes care of the rest, following your established plan and reducing the brain work of daily investing.</li>
            </ul>
          </div>
        </div>
        <div className={styles.flexContainer}>
          <div className={styles.flexCopy}>
            <h2>Track your progress with detailed reporting</h2>
            <p>Keeping track of everything is tricky, but the big picture never seems too big with our performance metrics.</p>
            <ul>
              <li>Get above and beyond insights with reporting on dividends and other metrics that your brokerage does not provide.</li>
              <li>Keep track of your earnings over any period to see how your investments are doing.</li>
              <li>A quick look offers you an overview of everything you need to know with a clean and simple dashboard.</li>
            </ul>
          </div>
          <div className={styles.flexImgBox}>
            <img src={graphB} srcSet={cn(graphB2x,'2x')} alt="Investment Graph" />
          </div>
        </div>
        <div className={styles.btn}>
          <a className={cn(styles.btn1,styles.registerBtn)} href={"/features"}>See all features</a>
        </div>
      </Container>
    </section>
  );
}

export default Features;
