import React, { useState, useEffect } from "react";
import Container from "../container";

import styles from "./brokerages.module.css";
import axios from "axios";

function setBrokerages(brokerages) {
  let brokerageTiles = [];
  brokerages.data.forEach((brokerage) => {
    if (brokerage.slug !== "PLAID") {
      let altText = brokerage.name + " Logo";
      brokerageTiles.push(
        <div>
          <a href={brokerage.open_url} target="_blank" rel="noopener noreferrer">
            <div className={styles.tile}>
              <img src={brokerage.aws_s3_logo_url} alt={altText} />
            </div>
          </a>
        </div>
      );
    }
  });
  return brokerageTiles;
}

function Brokerages({}) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    swipeToSlide: true,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [brokerageTiles, setBrokerageTiles] = useState([]);

  useEffect(() => {
    axios.get(`https://api.passiv.com/api/v1/brokerages/`).then((response) => {
      setBrokerageTiles(setBrokerages(response));
    });
  }, []);

  return (
    <section className={styles.brokerages} id="brokerages">
      <Container>
        <div className={styles.copyBox}>
          <h2>Connect to a wide variety of Brokerages</h2>
          <p>
            Connect your accounts from any of these partner platforms to start using Passiv today.
            Don't worry; if your platform isn't listed below, you can join the mailing list for
            notification when it's available. We are constantly working on adding more.
          </p>
        </div>
      </Container>
      <div className={styles.tileContainer}>{brokerageTiles}</div>
    </section>
  );
}

export default Brokerages;
